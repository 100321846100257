var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"library"},[_c('div',{staticClass:"title"},[_c('el-form',{attrs:{"inline":true,"model":_vm.searchForm,"onsubmit":"return false"}},[_c('el-form-item',{attrs:{"label":"保险名称","prop":"name"}},[_c('el-input',{attrs:{"placeholder":"请输入保险名称","clearable":""},model:{value:(_vm.searchForm.name),callback:function ($$v) {_vm.$set(_vm.searchForm, "name", $$v)},expression:"searchForm.name"}})],1),_c('el-form-item',[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.onSearch}},[_vm._v("查询")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.goAdd}},[_vm._v("新增")]),_c('el-switch',{staticStyle:{"margin-left":"20px"},attrs:{"active-text":"强制购买"},on:{"change":_vm.handleChangeBuy},model:{value:(_vm.isCompelBuy),callback:function ($$v) {_vm.isCompelBuy=$$v},expression:"isCompelBuy"}})],1)],1)],1),_c('div',[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.list}},[_c('el-table-column',{attrs:{"prop":"name","fixed":"","width":"200","label":"保险名称"}}),_c('el-table-column',{attrs:{"prop":"fee","width":"200","label":"保险费"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return (row.fee)?[_vm._v(" "+_vm._s(row.fee)+"元 ")]:undefined}}],null,true)}),_c('el-table-column',{attrs:{"prop":"money","width":"200","label":"保额"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return (row.money)?[_vm._v(" "+_vm._s(row.money)+"元 ")]:undefined}}],null,true)}),_c('el-table-column',{attrs:{"prop":"day","width":"200","label":"保险天数"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return (row.day)?[_vm._v(" "+_vm._s(row.day)+"天 ")]:undefined}}],null,true)}),_c('el-table-column',{attrs:{"prop":"createTime","label":"创建时间","min-width":"150"}}),_c('el-table-column',{attrs:{"fixed":"right","width":"200","label":"操作"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.goEdit(row)}}},[_vm._v("编辑")]),_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.del(row)}}},[_vm._v("删除")])]}}])})],1)],1),_c('div',{staticClass:"fr mt20"},[_c('el-pagination',{attrs:{"current-page":_vm.beginIndex,"page-size":_vm.pageSize,"page-sizes":[10, 20, 30, 50, 100, 150, 200],"layout":"total, sizes, prev, pager, next, jumper","total":_vm.total},on:{"current-change":_vm.handleCurrentChange,"update:currentPage":function($event){_vm.beginIndex=$event},"update:current-page":function($event){_vm.beginIndex=$event},"size-change":_vm.handleSizeChange}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }