// export const reg = {
//   Email: /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/, // 邮箱
//   Account: /^[a-zA-Z0-9_]{2,20}$/, // 账户
//   Pwd: /^[a-zA-Z0-9_~!@#$%^&*()]{6,25}$/i, // 密码
//   Tel: /^(13|14|15|16|18|17|19)[0-9]{9}$/, // 手机
//   FixedAndTel: /(^0\d{2,3}-\d{7,8}$)|(^1[3|4|5|6|7|8][0-9]{9}$)/, // 座机或手机号
//   IDCard: /^\d{17}[\d|X|x]|\d{15}$/, // 身份证
//   Number: /^\d+$/, // 数字
//   IntegerNumber: /^[1-9]\d*(\.\d+)?$/, // 大于0必须输入合同金额(数字)!")
//   // Integer: /^[-\+]?\d+$/, // 正负整数
//   IntegerZ: /^[1-9]\d*$/, // 正整数
//   IntegerF: /^-[1-9]\d*$/, // 负整数
//   Chinese: /^[\u0391-\uFFE5]+$/,
//   Zipcode: /^\d{6}$/, // 邮编
//   Authcode: /^\d{6}$/, // 验证码
//   QQ: /^\d{4,12}$/, // QQ
//   Price: /^(0|[1-9]\d*)(\.\d{1,2})?$/, // 价格
//   Money: /^(0|[1-9]\d*)(\.\d{1,4})?$/, // 金额
//   Letter: /^[A-Za-z]+$/, // 字母
//   LetterU: /^[A-Z]+$/, // 大写字母
//   LetterL: /^[a-z]+$/, // 小写字母
//   // Url: /^http:\/\/[A-Za-z0-9]+\.[A-Za-z0-9]+[\/=\?%\-&_~`@[\]\':+!]*([^<>\"\"])*$/, // URL
//   // Date: /^\d{4}(\-|\/|\.)\d{1,2}\1\d{1,2}$/, // 日期
//   Domain: /^[a-zA-Z0-9]{4,}$/ // 自定义域名
// }
export default {
  hasEmoji(str = ""){
    var regex = /[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF][\u200D|\uFE0F]|[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF]|[0-9|*|#]\uFE0F\u20E3|[0-9|#]\u20E3|[\u203C-\u3299]\uFE0F\u200D|[\u203C-\u3299]\uFE0F|[\u2122-\u2B55]|\u303D|[\A9|\AE]\u3030|\uA9|\uAE|\u3030/ig
    return regex.test(str);
  },
  // 是否是手机
  isPoneAvailable(pone) {
    var reg = /^[1][3-9][0-9]{9}$/;
    if (!reg.test(pone)) {
      return false;
    } else {
      return true;
    }
  },
  // 检验昵称
  verifyNickname(name) {
    var myreg = /^.{1,16}$/;
    return myreg.test(name);
  },
  // 正整数
  integerZ(val) {
    var reg = /^[1-9]\d*$/;
    return reg.test(val);
  },
  // 是否是微信号
  isWeChatId(data) {
    var myreg = /^[a-zA-Z0-9_\-]{1,20}$/;
    if (!myreg.test(data)) {
      return false;
    } else {
      return true;
    }
  },
  // 验证邮箱
  isEmail(email) {
    var reg = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
    if (!reg.test(email)) {
      return false;
    } else {
      return true;
    }
  },

  // 验证金额
  isDigit(s) {
    var patrn = /^(\d+)$|^(\d+\.\d{0,2})$/;
    if (!patrn.exec(s)) return false;
    return true;
  },
  // 验证密码
  validatePwd(str) {
    if (
      /^.*?[\d]+.*$/.test(str) &&
      /^.*?[A-Za-z]/.test(str) &&
      /^.*?[~/`!@#$%^&*()_+|{}?;:><\-\]\\[\/].*$/.test(str) &&
      /^.{8,20}$/.test(str)
    ) {
      return true;
    }
    return false;
  },

  // 判断是否是汉字
  isCharacter(charValue) {
    var reg = /^[\u4e00-\u9fa5]{0,}$/;
    return reg.test(charValue);
  },

  /**
   * desc: 判断是否是姓名，并且对藏族名称做了处理
   * @param  charValue 
   */
  isCheckValidName(charValue){
    var reg = /(^[\u4e00-\u9fa5]{0,}$)|^[\u4e00-\u9fa5]+·[\u4e00-\u9fa5]+$/;
    return reg.test(charValue);
  },

  // 判断是否是字母
  isChar(charValue) {
    var charPattern = /^[a-zA-Z]*$/; // 是否为字母
    var result = charPattern.test(charValue);
    return result;
  },

  // 判断是否数字
  isNum(numValue) {
    var numPattern = /^\d+$/;
    var result = numPattern.test(numValue);
    return result;
  },
  // 整数的正则表达式
  isInt(intValue) {
    var intPattern = /^0$|^[1-9]\d*$/;
    var result = intPattern.test(intValue);
    return result;
  },

  // 是否为字母和数字
  isCharNum(flagValue) {
    var flagPattern = /^[a-zA-Z0-9]*$/;
    var result = flagPattern.test(flagValue);
    return result;
  },
  // 是否为京东商家码
  isJDShopperCode(flagValue) {
    var flagPattern = /^[a-zA-Z0-9]*$/;
    var result = flagPattern.test(flagValue);
    return result;
  },

  // 判断是否是名字
  isUserName(charValue) {
    var reg = /^[\u4e00-\u9fa5]{1,}((·[\u4e00-\u9fa5]{1,}){0,3})$/;
    return reg.test(charValue);
  },

  idCardValidate(idCard) {
    idCard = this.trim(idCard.replace(/ /g, '')); //去掉字符串头尾空格
    if (idCard.length == 15) {
      return this.isValidityBrithBy15IdCard(idCard); //进行15位身份证的验证
    } else if (idCard.length == 18) {
      var a_idCard = idCard.split(''); // 得到身份证数组
      if (
        this.isValidityBrithBy18IdCard(idCard) &&
        this.isTrueValidateCodeBy18IdCard(a_idCard)
      ) {
        //进行18位身份证的基本验证和第18位的验证
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  },
  /**
   * 判断身份证号码为18位时最后的验证位是否正确
   * @param a_idCard 身份证号码数组
   * @return
   */

  isTrueValidateCodeBy18IdCard(a_idCard) {
    var sum = 0; // 声明加权求和变量
    if (a_idCard[17].toLowerCase() == 'x') {
      a_idCard[17] = 10; // 将最后位为x的验证码替换为10方便后续操作
    }
    for (var i = 0; i < 17; i++) {
      sum += Wi[i] * a_idCard[i]; // 加权求和
    }
    let valCodePosition = sum % 11; // 得到验证码所位置
    if (a_idCard[17] == ValideCode[valCodePosition]) {
      return true;
    } else {
      return false;
    }
  },
  /**
   * 验证18位数身份证号码中的生日是否是有效生日
   * @param idCard 18位书身份证字符串
   * @return
   */

  isValidityBrithBy18IdCard(idCard18) {
    var year = idCard18.substring(6, 10);
    var month = idCard18.substring(10, 12);
    var day = idCard18.substring(12, 14);
    var temp_date = new Date(year, parseFloat(month) - 1, parseFloat(day));
    // 这里用getFullYear()获取年份，避免千年虫问题
    if (
      temp_date.getFullYear() != parseFloat(year) ||
      temp_date.getMonth() != parseFloat(month) - 1 ||
      temp_date.getDate() != parseFloat(day)
    ) {
      return false;
    } else {
      return true;
    }
  },
  /**
   * 验证15位数身份证号码中的生日是否是有效生日
   * @param idCard15 15位书身份证字符串
   * @return
   */

  isValidityBrithBy15IdCard(idCard15) {
    var year = idCard15.substring(6, 8);
    var month = idCard15.substring(8, 10);
    var day = idCard15.substring(10, 12);
    var temp_date = new Date(year, parseFloat(month) - 1, parseFloat(day));
    // 对于老身份证中的你年龄则不需考虑千年虫问题而使用getYear()方法
    if (
      temp_date.getYear() != parseFloat(year) ||
      temp_date.getMonth() != parseFloat(month) - 1 ||
      temp_date.getDate() != parseFloat(day)
    ) {
      return false;
    } else {
      return true;
    }
  },
  //去掉字符串头尾空格
  trim(str) {
    return str.replace(/(^\s*)|(\s*$)/g, '');
  },
  // 过滤全部空格
  replaceSpace(str) {
    return str.replace(/\s+/g, '');
  },

  // 检查是否存在表情符
  checkEmoji(str) {
    const reg = /[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF][\u200D|\uFE0F]|[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF]|[0-9|*|#]\uFE0F\u20E3|[0-9|#]\u20E3|[\u203C-\u3299]\uFE0F\u200D|[\u203C-\u3299]\uFE0F|[\u2122-\u2B55]|\u303D|[\A9|\AE]\u3030|\uA9|\uAE|\u3030/ig
    if (reg.test(str)) {
      return true;
    } else {
      return false;
    }
  },
  /**
   * 价格校验
   */
  verifyPrice(price) {
    let regex = /(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/;
    return regex.test(price)
  }

};