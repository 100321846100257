<template>
  <div class="library">
    <div class="title">
      <el-form :inline="true" :model="searchForm" onsubmit="return false">
        <el-form-item label="保险名称" prop="name">
          <el-input
            placeholder="请输入保险名称"
            v-model="searchForm.name"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearch">查询</el-button>
          <el-button type="primary" @click="goAdd">新增</el-button>
          <el-switch
            v-model="isCompelBuy"
            active-text="强制购买"
            style="margin-left:20px"
            @change="handleChangeBuy"
          >
          </el-switch>
        </el-form-item>
      </el-form>
    </div>
    <div>
      <el-table :data="list" style="width: 100%">
        <el-table-column prop="name" fixed width="200" label="保险名称">
        </el-table-column>
        <el-table-column prop="fee" width="200" label="保险费">
          <template v-if="row.fee" slot-scope="{row}">
            {{row.fee}}元
          </template>
        </el-table-column>
        <el-table-column prop="money" width="200" label="保额">
           <template v-if="row.money" slot-scope="{row}">
            {{row.money}}元
          </template>
        </el-table-column>
        <el-table-column prop="day" width="200" label="保险天数">
           <template v-if="row.day" slot-scope="{row}">
            {{row.day}}天
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间" min-width="150">
        </el-table-column>
        <el-table-column fixed="right" width="200" label="操作">
          <template slot-scope="{ row }">
            <el-button type="text" @click="goEdit(row)">编辑</el-button>
            <el-button type="text" @click="del(row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="fr mt20">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page.sync="beginIndex"
        :page-size="pageSize"
        @size-change="handleSizeChange"
        :page-sizes="[10, 20, 30, 50, 100, 150, 200]"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {
  queryInsuranceList,
  deleteInsurance,
  updateInsuranceIsCompelBuy,
  isInsurance
} from "@/api/goods";

export default {
  data() {
    return {
      searchForm: {
        name: ""
      },
      beginIndex: 1,
      pageSize: 20,
      list: [],
      total: 0,
      isCompelBuy: false //强制购买
    };
  },
  beforeDestroy() {
    document.onkeydown = null
  },
  mounted() {
    const _this = this;
    document.onkeydown = function(e) {
      let key = window.event.keyCode;
      if (key == 13) {
        _this.onSearch()
      }
    };
    this.queryInsuranceList();
    this.isInsurance()
  },
  beforeRouteEnter (to, from, next) {
    if (from.path === '/leaseholdMgmt/addInsurance') {
      to.meta.isBack = true;
    } else {
      to.meta.isBack = false
    }
    next()
  },
  activated () {
    if (this.$route.meta.isBack) {
      this.$route.meta.isBack = false // 重置详情页标识isBack
    }
    this.queryInsuranceList();
    this.isInsurance()
  },
  methods: {
    onSearch() {
      this.beginIndex = 1;
      this.queryInsuranceList();
    },
    async handleChangeBuy() {
      const params = {
        isCompelBuy: this.isCompelBuy ? "1" : "0"
      };
      await updateInsuranceIsCompelBuy(params);
      let msg = this.isCompelBuy ? "开启成功" : "关闭成功";
      this.$message.success(msg);
    },
    async queryInsuranceList() {
      let page = this.beginIndex;
      page = page - 1;
      const params = {
        ...this.searchForm,
        beginIndex: page,
        pageSize: this.pageSize
      };
      const res = await queryInsuranceList(params);
      this.list = res.ldata;
      this.total = res.mdata.total || 0;
    },
    /** 分页 */
    handleCurrentChange(page) {
      this.beginIndex = page;
      this.queryInsuranceList();
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.onSearch();
    },
    /**删除 */
    del(row) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(async () => {
          const params = {
            insuranceNo: row.insuranceNo
          };
          await deleteInsurance(params);
          this.$message.success("删除成功");
          this.beginIndex = 1;
          this.queryInsuranceList();
        })
        .catch(() => {});
    },
    goAdd() {
      this.$store.dispatch('login/checkHandle', '/managementPlatform/lease/addOrUpdateInsurance' ).then((flag) => {
        if(flag) {
          this.$router.push("/leaseholdMgmt/addInsurance");
        } else {
          this.$message.error('暂无权限！')
        }
      }).catch(() => {
        this.$router.push("/leaseholdMgmt/addInsurance");
      })
     
    },
    goEdit(row) {
      this.$store.dispatch('login/checkHandle', '/managementPlatform/lease/addOrUpdateInsurance' ).then((flag) => {
        if(flag) {
          this.$router.push({
            path: "/leaseholdMgmt/addInsurance",
            query: {
              insuranceNo: row.insuranceNo,
              type: "edit",
              name: row.name,
              fee: row.fee,
              money: row.money,
              day: row.day
            }
          });
        } else {
          this.$message.error('暂无权限！')
        }
      }).catch(() => {
         this.$router.push({
          path: "/leaseholdMgmt/addInsurance",
          query: {
            insuranceNo: row.insuranceNo,
            type: "edit",
            name: row.name,
            fee: row.fee,
            money: row.money,
            day: row.day
          }
        });
      })
     
    },
    async isInsurance() {
      const res = await isInsurance()
      this.isCompelBuy = res.mdata.status == 1

    }
  }
};
</script>

<style scoped>
.library {
  padding-bottom: 80px;
}
</style>
