<template>
  <div class="addGoods">
    <el-form
      class="form"
      ref="form"
      :model="form"
      label-width="100px"
      :rules="rules"
    >
      <el-form-item label="保险名称" prop="name">
        <el-input
          v-model="form.name"
          style="width: 300px"
          placeholder="请输入保险名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="保费" prop="fee">
        <el-input
          v-model="form.fee"
          style="width: 300px"
          placeholder="请输入保费"
        ></el-input>
      </el-form-item>
      <el-form-item label="保额" prop="money">
        <el-input
          v-model="form.money"
          style="width: 300px"
          placeholder="请输入保额"
        ></el-input>
      </el-form-item>
      <el-form-item label="有效天数" prop="day">
        <el-input-number
          v-model="form.day"
          :min="1"
          style="width: 300px"
          placeholder="请输入有效天数"
        ></el-input-number>
      </el-form-item>
    </el-form>
    <div>
      <el-table
        @selection-change="handleSelectionChange"
        :data="insuranceList"
        style="width: 100%"
        ref="multipleTable"
      >
        <!-- :row-key="getRowKeys" -->
        <el-table-column type="selection" width="55" :selectable="checkSelectable"> </el-table-column>
        <el-table-column prop="agentName" label="代理商"> </el-table-column>
        <el-table-column prop="storeName" label="门店"> </el-table-column>
        <el-table-column prop="commodityType" label="商品规格" min-width="130">
          <template slot-scope="{ row }">
            {{ $changeObj(PRODUCT_TYPE)[row.commodityType] }}
          </template>
        </el-table-column>
        <el-table-column prop="specification" label="商品规格">
        </el-table-column>
        <!-- <el-table-column prop="cashPledge" label="押金"> </el-table-column>
        <el-table-column prop="singleRent" label="单期租金" min-width="150">
        </el-table-column>
        <el-table-column prop="validDay" label="有效时长" min-width="150">
        </el-table-column> -->
        <el-table-column prop="insuranceName" label="已绑定保险">
        </el-table-column>
      </el-table>
    </div>
    <div style="margin: 50px 0 0 0;">
      <el-button type="primary" @click="submitForm" :loading="disaled"
        >确认</el-button
      >
      <el-button type="info" @click="goBack">返回</el-button>
    </div>
  </div>
</template>

<script>
import { PRODUCT_TYPE } from "@/js/constant";
import { getGoodsList, saveInsurance ,  queryInsuranceDetail} from "@/api/goods";
import Reg from "@/js/reg";

export default {
  data() {
    const validFee = (rule, value, callback) => {
      if (value == "") {
        callback();
      } else if (!Reg.isDigit(value)) {
        callback(new Error("请输入正确价格!"));
      } else {
        callback();
      }
    };
    const validMoney = (rule, value, callback) => {
      if (value == "") {
        callback();
      } else if (!Reg.isDigit(value)) {
        callback(new Error("请输入正确价格!"));
      } else {
        callback();
      }
    };
    return {
      form: {
        name: "",
        fee: "",
        money: "",
        day: ""
      },
      rules: {
        name: [{ required: true, message: "请输入保险名称", trigger: "blur" }],
        fee: [
          { required: true, message: "请输入保费", trigger: "blur" },
          { validator: validFee, trigger: "blur" }
        ],
        money: [
          { required: true, message: "请输入保额", trigger: "blur" },
          { validator: validMoney, trigger: "blur" }
        ],
        day: [{ required: true, message: "请输入有效天数", trigger: "blur" }]
      },
      disaled: false,
      PRODUCT_TYPE,
      /*管理列表*/
      insuranceList: [],
      /** 选择的保险 */
      selectinsuranceList: [],
      arr:[],
    };
  },
  mounted() {
    if (this.$route.query.type == "edit") {
      this.form = {
        name: this.$route.query.name,
        fee: this.$route.query.fee,
        money: this.$route.query.money,
        day: this.$route.query.day,
        insuranceNo:this.$route.query.insuranceNo

      };
      this.queryInsuranceDetail();

    }
    this.getGoodsList();

  },
  methods: {
    //
     getRowKeys(row) {
      return row.productNo;
    },
    async queryInsuranceDetail(){
       const params = {
        insuranceNo:this.form.insuranceNo
      };
       const res = await queryInsuranceDetail(params);
       this.arr =res.mdata.leaseInsurance.commodityNos;
    },
    //
    submitForm() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.disaled = true;
          const params = {
            ...this.form,
            type: 3
          };
          try {
            if (this.$route.query.type == "edit") {
              params.insuranceNo = this.$route.query.insuranceNo;
            }
            params.commodityNos = this.selectinsuranceList.map(
              ele => ele.commodityNo
            );
            await saveInsurance(JSON.stringify(params));
            let tips =
              this.$route.query.type == "edit" ? "编辑成功" : "新增成功";
            this.$message.success(tips);
            setTimeout(() => {
              this.$router.push("/leaseholdMgmt/insurance");
            }, 1000);
          } catch (error) {
            this.disaled = false;
          }
        } else {
          return false;
        }
      });
    },
    goBack() {
      this.$router.push('/leaseholdMgmt/insurance');

    },
    /**获取管理列表 */
    async getGoodsList() {
      const params = {
        beginIndex: 0,
        pageSize: 500,
        type: 1
      };
      const res = await getGoodsList(params);
      this.insuranceList = res.ldata;
       //
      let arr = [];
      this.arr.forEach(el=>{
        this.insuranceList.forEach(el2=>{
          if(el2.commodityNo == el){
            arr.push(el2)
          }
        })
      })
       this.$nextTick(()=>{
         arr.forEach(el => {
        this.$refs.multipleTable.toggleRowSelection(el);
      })
       })
    //
    },
    handleSelectionChange(row) {
      this.selectinsuranceList = row;
    },
    checkSelectable(row) {
      if (this.$route.query.type) {
        return (this.$route.query.insuranceNo == row.insuranceNo) || !row.insuranceNo
      }
      return  !row.insuranceNo
    }
  }
};
</script>

<style scoped lang="less">
.addGoods {
  padding-bottom: 100px;
  .scheme {
    i {
      font-size: 28px;
      margin-left: 10px;
    }
  }
}
</style>
